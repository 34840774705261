.container {
    padding-right: 10%;
    padding-left: 10%;
    margin-right: auto;
    margin-left: auto;
}
.grid{
    margin-top: 0;
    margin: 0;
}

.navbar {
    background-color: #283552;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: absolute;
    width: 100%;
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1500px;
}

.main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
    align-items: center;
    margin-left: 20px;
    flex-grow: 1;
    width: 20%;
}
.mySROLogo{
    width: 70%;
}
.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    width: 80%;
    justify-content: flex-end;
}

.nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
    font-size: 14px;
    font-weight: 500;
    margin-left: 3px;
    position: relative;
}
.dropdown .nav-links::after {
    content: "\e930";
    font-size: 16px;
    line-height: 1;
    font-family: "primeicons";
    position: absolute;
    right: -5px;
    top: 11px;
}
.is-sticky .nav-links{
    color: #000;
    margin-left: 0px;
}
a {
    transition: all 0.35s ease-in-out;
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
}

.nav-item {
    line-height: 40px;
    margin-right: 2rem;
    padding: 1em 0px;
    position: relative;
}

.nav-item:after {
    content: "";
    display: block;
    height: 3px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
    position: absolute;
    bottom: 0px;
}

.nav-item:hover:after {
    width: 100%;
    background: #2dcdbc;
}

.nav-item.active {
    color: #2dcdbc;
    border: 1px solid #2dcdbc;
}

.nav-icon {
    display: none;
}
.nav-item .active{
    color: #2dcdbc;
}


/*TopBar*/
.top-bar-area ul {
    margin: 0;
}
.top-bar-area {
    /* padding: 5px 0; */
    position: relative;
    background: #283552;
}
.top-bar-area .info.box {
    margin-top: 8px;
}
.top-bar-area .info {
    display: inline-block;
}
.top-bar-area .info li:first-child {
    border: medium none;
    margin: 0;
    padding: 0;
}
.top-bar-area .info li {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    color: #ffffff;
    display: inline-block;
    font-family: "Montserrat", sans-serif;
    margin-left: 10px;
    padding-left: 15px;
    margin-top: 5px;
}
.top-bar-area .info li .icon {
    float: left;
}
.top-bar-area .info li i {
    color: #ffffff;
    font-size: 18px;
    text-align: center;
}
.top-bar-area .info li .info {
    float: left;
    padding-left: 15px;
    font-size: 14px;
}
.top-bar-area .info li p {
    font-weight: 600;
    margin: 0;
    text-align: left;
    text-transform: uppercase;
}
.top-bar-area .topbar-social {
    line-height: 36px;
}
.text-right {
    text-align: right;
}
.topbar-social li {
    margin-left: 20px;
    display: inline-block;
}
.top-bar-area a {
    font-size: 16px;
    color: #fff;
}
.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 0px;
    padding-bottom: 0px;
}
.consultaionHeading{
    text-align: center;
    background: #2dcdbc !important;
    padding: 0.7rem !important;
    color: #283552 !important;
}
.appButton .p-button.appointment {
    background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: background-position 0.5s ease-out;
    color: #fff;
    border-color: #2dcdbc;
    font-weight: 600;
}

.appButton .p-button.appointment:enabled:hover {
    background-position: left bottom;
    color: #fff;
    background: linear-gradient(to left, #2dcdbc 50%, #283552 50%);
    transition: background-position 0.5s ease-out;
    border: 1px solid #2dcdbc;
    background-size: 200% 100%;
}
.appButton .p-button.appointment:focus {
    box-shadow: 0 0 0 1px transparent;
}
.appButton {
    line-height: 40px;
    margin-right: 1rem;
    padding: 1em 0px;
    position: relative;
}
.dropdown:hover .dropdownList{
    display: block;
    opacity: 1;
}
.dropdownList .nav-item {
    line-height: initial;
    margin-right: 0;
    padding: unset;
    position: relative;
}
.dropdownList {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
}
ul.dropdownList {
    background: #fff none repeat scroll 0 0;
    border: 1px solid transparent;
    border-radius: 2px;
    box-shadow: 0 5px 50px 0 rgb(0 0 0 / 15%);
    padding: 0;
    width: 220px;
}
ul.dropdownList li a {
    border-bottom: medium none;
    color: #333333;
    display: block;
    font-size: 12px;
    margin-bottom: 0;
    padding: 12px 15px;
    text-align: left;
    width: 100%;
}
.height80{
    height: 80px;
}
body .text-primary{
    color: #283552 !important;
}


@media screen and (max-width: 960px) {

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1pxsolid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #283552;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item .active {
        color: #2dcdbc;
        border: none;
    }

    .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #2dcdbc;
    }
    .top-bar-area{
        display: none;
    }
    .navbar{
        background-color: #283552;
    }
}